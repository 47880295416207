<template>
  <el-skeleton animated>
    <template slot="template">
      <el-skeleton-item variant="image" style="width: 100%; height: 50px" />
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: 'ImageSkeleton'
}
</script>

<style scoped>

</style>