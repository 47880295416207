<template>
  <div class="widgetContainer checkDeposit__details">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span v-if="!fromCreate" class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          Check Deposit
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body widgetContainer--scrollable">
      <template v-if="fromCreate">
        <div class="check__success_lottie">
          <LottieAnimation animation-name="success" reference="check-success" :loop="true" />
        </div>
        <p class="check__success_description my-8">
          {{ $t('RCD_successDesc') }}
        </p>
      </template>
      <el-card class="mb-4">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <el-tag :class="'el-tag--blue transaction-type type-' + check.status">
            {{ check.status }}
          </el-tag>
          <div class="details">
            <p class="label">
              {{ check.name }}
            </p>
            <p class="value">
              {{ check.transferredAt | date("MMM d, yyyy 'at' h:mm aa") }}
            </p>
          </div>
          <div :class="
            'amount ' +
              (check.status == 'pending'
                ? 'pending'
                : check.txnType === 'debit'
                  ? 'minus'
                  : 'plus')
          ">
            {{ check.amount | transactionAmount }}
          </div>
        </div>
        <hr>
        <div>
          <div>
            <p v-if="isBusinessAccountType">
              <span class="font-bold">Business: </span>{{ getSelectedBusiness.legalName }}
            </p>
            <p>
              <span class="font-bold">Account: </span>{{ getSelectedAccount.label }}
            </p>
          </div>
        </div>
      </el-card>
      <div class="uploadCheckImage__image">
        <CheckDepositDetailsAction class="mb-4" @openViewDialog="openViewDialog" @downloadImage="downloadImage" @openUpload="openUpload" :loading="loading" :check="check" :from="'front'" />
        <CheckDepositDetailsAction @openViewDialog="openViewDialog" @downloadImage="downloadImage" @openUpload="openUpload" :loading="loading" :check="check" :from="'back'" />
        <el-dialog :visible.sync="isViewing" :fullscreen="$store.getters['getIsMobileLayout']" :append-to-body="true">
          <img style="width: 100%; height: 100%"
               ref="view_image_el"
               alt="image">
        </el-dialog>
        <a style="display: none" ref="image_download_link" download="file.jpeg">download</a>
      </div>
      <el-dialog title="Upload Image" @close="toUpload = {front: null, back:null}" :fullscreen="$store.getters.getIsMobileLayout" :destroy-on-close="true" :append-to-body="true" :visible.sync="uploadToggle">
        <div class="mb-2">
          <div class="font-bold font-18 mb-2">
            Check {{ isWaitingForFiles ? uploadType : 'front' }} image
          </div>
          <FileSelect @fileChange="fileOnChange($event,uploadType)" upload-btn-text="Select Image" reselect-btn-text="Choose">
            <ul>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>
        </div>
        <div class="mb-2" v-if="isWaitingForFiles">
          <div class="font-bold font-18 mb-2">
            Check {{ 'back' }} image
          </div>
          <FileSelect @fileChange="fileOnChange($event,'back')" upload-btn-text="Select Image" reselect-btn-text="Choose">
            <ul>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button :disabled="saveDisabled" @click="upload(uploadType)" style="width: 100%" type="primary" class="brand">
            Save Image
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {receiveService} from '@/services/solidfi';
import UTIF from '@/utils/UTIF';
import axios from 'axios'
import LottieAnimation from '@/components/LottieAnimation';
import FileSelect from '@m/check-deposit/components/FileSelect';
import CheckDepositDetailsAction from '@m/check-deposit/components/CheckDepositDetailsAction';
export default {
  name: 'CheckDepositDetails',
  components: {CheckDepositDetailsAction, FileSelect, LottieAnimation},
  computed: {
    ...mapGetters({
      check: 'checkDeposit/getCheckDeposit',
      getSelectedBusiness: 'kyb/getSelectedBusiness',
      getSelectedAccount: 'account/getSelectedAccount',
      isBusinessAccountType: 'account/isBusinessAccountType',
    }),
    isWaitingForFiles(){
      return this.check.status === 'waitingForFiles'
    },
    canChangeFiles() {
      return ['pending'].includes(this.check.status)
    },
    fromCreate(){
      return this.$store.getters['checkDeposit/getSuccessDepositData'] !== null
    },
    saveDisabled(){
      return this.isWaitingForFiles ? this.toUpload.front == null || this.toUpload.back == null : this.toUpload.front == null && this.toUpload.back == null
    },
  },
  data() {
    return {
      loading: {
        front: true,
        back: true
      },
      isViewing: true,
      toUpload:{
        front: null,
        back: null,
      },
      uploadToggle: false,
      uploadType: 'back'
    }
  },
  async created() {
    const recurseFn = async () => {
      await this.getCheckImages()
      if (!this.isWaitingForFiles && (!this.check.images?.front?.original?.length || !this.check.images?.back?.original?.length)){
        this.loading.front = this.loading.back = true
        setTimeout(async () => {
          await recurseFn();
        },3000)
      }
    }
    await recurseFn()
  },
  beforeDestroy() {
    this.$store.commit('checkDeposit/setSuccessDepositData',null)
  },
  beforeMount() {
    this.isViewing = false
  },
  methods: {
    upload(from){
      const loader = this.showLoader()
      const formData = new FormData();
      from = (this.isWaitingForFiles) ? ['front','back'] : [from];
      from.forEach(fr => formData.append(fr, this.toUpload[fr]));
      formData.append('accountId', this.getSelectedAccount.id);
      receiveService.uploadCheckFiles({
        id: this.check.id,
        data: formData
      })
        .then(async () => {
          this.check.status = 'pending'
          from.forEach(fr => {
            this.loading[fr] = true
            this.toUpload[fr] = null;
          })
          this.uploadToggle = false;
          setTimeout(async () => {
            const result = await receiveService.getUploadCheckFiles(this.check.id);
            this.check.images = result.data
            from.forEach(fr => this.loading[fr] = false)
          },7000)
        })
        .catch((e) => {
          console.log(e.response);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    fileOnChange(e,from){
      this.toUpload[from] = e
    },
    async getCheckImages() {
      this.loading.front = this.loading.back = true
      const result = await receiveService.getUploadCheckFiles(this.check.id);
      this.check.images = result.data
      this.loading.front = this.loading.back = false
    },
    openUpload(from){
      this.uploadToggle = true
      this.uploadType = from
    },
    async openViewDialog(from, type) {
      const loader = this.showLoader()
      await this.getCheckImages();
      const responseType = type === 'original' ? 'blob' : 'arraybuffer'
      const src = this.check.images[from][type]
      const {data} = await axios.get(src,{
        responseType: responseType
      })
      const url = responseType === 'blob' ? window.URL.createObjectURL(data) : UTIF.bufferToURI(data)
      this.isViewing = true
      this.$nextTick(() => {
        this.$refs.view_image_el.src = url
      })
      loader.close()
    },
    async downloadImage(from,type){
      const loader = this.showLoader()
      let src = this.check.images[from][type]
      const downloadLinkEl = this.$refs.image_download_link;
      downloadLinkEl.download = `${from}-${type}.jpeg`
      const response = await axios.get(src, {
        responseType: 'blob'
      })
      src = window.URL.createObjectURL(response.data)
      downloadLinkEl.href = src
      downloadLinkEl.click()
      URL.revokeObjectURL(src)
      loader.close()
    }
  }
}
</script>

<style lang="scss">
.checkDeposit__details {
  .uploadCheckImage__image {
    height: auto;
    .el-card__body{
      .image__container{
        height: 150px;
        overflow: auto;
      }
    }
    img {
      cursor: pointer;
      width: 100%;
    }
  }
  .check__success_lottie{
    display: flex;
    justify-content: center;
    .check-success{
      width: 150px;
    }
  }
  .check__success_description{
    text-align: center;
    color: rgba(60, 60, 67, 0.6);
  }
  .amount {
    font-weight: 500;
    &.minus {
      color: #ff1756;
    }

    &.plus {
      color: var(--success-color);
    }

    &.pending {
      color: var(--orange2);
    }
  }
  .details{
    .label{
      font-weight: bold;
    }
    .value{
      color: rgba(60, 60, 67, 0.6);
      font-size: 14px;
      padding-top: 6px;
    }
  }
}
</style>