<template>
  <el-card class="back__image_card">
    <div class="font-bold font-18 mb-1 d-flex justify-content-between align-items-center">
      <div>
        {{ fromCap }} Image
      </div>
      <el-button type="text" icon="el-icon-upload" @click="$emit('openUpload',from)">
        Re-upload
      </el-button>
    </div>
    <template v-if="loading[from]">
      <ImageSkeleton />
    </template>
    <div v-else>
      <template v-if="check.images[from].original.length">
        <div class="mt-2 d-flex justify-content-between">
          <el-button @click="$emit('openViewDialog',from,'original')" size="mini" style="width: 100%">
            View Original
          </el-button>
          <el-button size="mini" style="width: 100%" @click="$emit('downloadImage',from,'original')">
            Download Original
          </el-button>
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <el-button @click="$emit('openViewDialog',from,'processed')" size="mini" style="width: 100%">
            View Processed
          </el-button>
          <el-button size="mini" style="width: 100%" @click="$emit('downloadImage',from,'processed')">
            Download Processed
          </el-button>
        </div>
      </template>
      <div v-else class="text-center">
        No {{ fromCap }} Image Yet. Please Upload
      </div>
    </div>
  </el-card>
</template>

<script>
import ImageSkeleton from '@m/check-deposit/views/ImageSkeleton';
export default {
  components: {ImageSkeleton},
  props:{
    check:{
      type: Object,
      default: () => {
        return {}
      }
    },
    from: {
      type: String,
      default: 'back'
    },
    loading:{
      type: Object,
      default: () => {
        return {
          front: false,
          back: false
        }
      }
    }
  },
  name: 'CheckDepositDetailsAction',
  computed:{
    fromCap(){
      return this.from === 'back' ? 'Back' : 'Front';
    }
  }
}
</script>

<style scoped>

</style>